.head{
    position:relative;
    height:1.5rem;
}
.iconAdd{
    color: #069998;
    position: absolute;
    font-size:19px;
    cursor:pointer;
    margin-top:-.95rem;
}

.btnHidden{
    border:0;
}

.divFilter{
    margin-top:1.3rem;
    display:flex;
    justify-content: start;
    align-items: center;
}

.filterMain{
    width:40%;
}

.filter{
    padding:.6rem 1.2rem;
    border: 1px solid #c9c9c9;
    border-radius: 1rem;
    outline: none;
    margin-left:.8rem;
}

.filter:nth-child(1){
    margin-left:0;
}

.movements{
    margin-top:2.5rem;
    display:flex; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.movements i{
    color:#aaa;
}

.titleMovements{
    color:#aaa;
}

.container{
    padding: 15px 0;
}


.rowReleases{
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-top:1.5rem;
    padding: .3rem;
}

.itemTypeAccount{
    text-align: center !important;
    width:35%;
}

.itemDescription{
    width:35%;
}


.itemValue{
    width:10%;
    text-align: right;
}



