.outModal{
    display:flex;
    margin: 0 auto !important;
    justify-content: center;
    align-items: center;
    
}

.containerModalMd{
    width: 100%;
    max-width: 760px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin: 0 auto;
    z-index: 9999;
    padding:1.2rem;
    border-radius: 8px;
    position: absolute;
    margin-left: -200px;

}

.containerModalSm{
    width: 100%;
    max-width: 380px;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin: 0 auto;
    z-index: 9999;
    padding:1.2rem;
    border-radius: 8px;
    position: absolute;
    margin-left: -200px;

}



.modalTitle{
    display:flex;
    justify-content: space-between;
}

.btnClose{
    cursor: pointer;
}

.contentModal{
    min-height:3rem;
    padding:30px 0 35px 0; 
}


.footerModal{
    display:flex;
    justify-content: end;
}

.footerModal button:nth-child(1){
    margin-right:1rem;
}


.btnSave{
    padding:.2rem .9rem !important;
    border-radius: 4px;
    background-color: #34A0A4;
    color:#fff;
    border:1px solid #34A0A4;
    cursor: pointer;
}

.btnExit{
    padding:.2rem .9rem !important;
    border-radius: 4px;
    background-color: #E63946;
    color:#fff;
    border:1px solid #E63946;
    cursor: pointer;
}


.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    background-color: #00000076;
}