@import url('https://fonts.googleapis.com/css2?family=Asap&display=swap');

* {
  padding:0;
  margin:0;
  overflow: hidden !important;
  font-family: 'Asap' !important;
}

body{
  background-color: #F6F6F6 !important;
  color:#5d5d5d !important;
}

.App{
  display:flex !important;
}


.navbarp{
  width:19.5% !important;
}

.containerp{
  width:100% !important;
  margin-right:1.5% !important;
  margin-top:.55rem !important;
}

@media screen and (max-width:1024px){
  .navbarp{
      width:10px !important;
  }
}


select, input{
  margin-top:.5rem !important;
  color:#5d5d5d !important;
  border:#d0d6d6 1px solid !important;
  border-radius: 4px !important;
  padding:.2rem !important;
  width: 100% !important;
  outline: none !important;
}

select{
  cursor: pointer !important;
}


label{
  font-weight: 600 !important;
  font-size: 14px !important;
}


textarea:focus, input:focus, select:focus {
  box-shadow: 0 0 0 0 !important;
  outline: 0 !important;
} 



.colorGreen{
  color:rgba(5, 141, 123, 0.705);
}

.colorRed{
  color:rgba(240, 84, 84, 0.781)
}