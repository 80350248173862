.divQuickAccess{
    display:flex;
    justify-content: space-between;
}


.divIntoQuickAcess{
    display:flex;
    justify-content: center;
    align-items: center;
}

.btn{
    border: 1px solid transparent;
    padding: .4rem .8rem;
    margin:.3rem;
    cursor: pointer;
}

.btnRecipe{

}

.btnExpense{

}




@media screen and (max-width:650px) {
    .divQuickAccess{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}