.logop{
    margin-bottom: .5rem !important;
    font-weight: 600 !important;
    color:#069998 !important;
    font-size:23px !important;
    padding: 1.5rem 0 0 2rem !important;
}


@media screen and (max-width:1024px){
    .navbarpm{
        display:none !important;
    }
}

.navbarpm{
    background-color: #fff !important;
    max-width: 235px !important;
    height: 100vh !important;
    
}

ul > li {
    list-style: none !important;
}


.menuItem{
    display: flex !important;
    justify-content: start !important;
    align-items: center !important;
    margin-top:1.3rem !important;
    height:2rem !important;
    font-size:15px !important;
    cursor: pointer !important;
    text-decoration: none !important;
    color:#333 !important;
}

.menuItem:nth-child(1){
    margin-top:1.55rem !important;
}



.active{
    font-weight: 600 !important;
    color:#069998 !important;
    border-right:2px solid #069998 !important;
    /* background: linear-gradient(to left, #06999815 , #fff 60%); */
}


.logout{
    position: absolute !important;
    bottom:2.5rem !important;

}