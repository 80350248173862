.containerpmp{
    width: 100%;
    min-height: 2rem;
    border-radius: 12px;
    display:flex;
    justify-content: end; 
    align-items: center;
    margin-top:1rem;
}

.title{
    width:80%;
    display:flex;
    justify-content: start;
    align-items: center;
}

.divNotifications{
    border-radius: 30px;
    margin-right:1rem;
    border:1px solid #eee;
    display:flex;
    justify-content: center;
    align-items: center;
    width:2.3rem;
    height: 2rem;
    background-color: #fff;
    padding-top:.2rem;
    padding-left:0.07rem;
}


.divProfile{
    width:20%;
    height: 2.5rem;
    background-color: #fff;
    border-radius: 30px;
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding:.2rem .3rem;
    border:1px solid #eee;
    font-size:14px;
}

.avatarProfile{
    max-width: 2rem;
    clip-path: circle();
}


.hello{
    font-size: 19px;
}


@media screen and (max-width:1280px) {
    .nameProfile{
        display:none;
    }

    .divProfile{width: 2rem;}
}