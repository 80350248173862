.title{
    font-size: 16px;
    margin-bottom:1rem;
}

.main{
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.result{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.percentBalance{
    display:flex;
    justify-content: end;
    align-items: center;
    height:1.4rem;
    background-color: #eee;
    min-width: 7rem;
    margin: .4rem 0rem;
    padding-right: .3rem;
}


.recipe{
    background-color:rgba(5, 141, 123, 0.705);
    color:#fff;
}

.expense{
    background-color:rgba(240, 84, 84, 0.781);
    color:#fff;
}